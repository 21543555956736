import type { Article, Group } from "../../../types/content";

const mapTeaserStyle = (dataGroup: Group) => {
  const firstArticle = dataGroup.find((item) => item.type === "Article");
  if (!firstArticle) {
    if (dataGroup[0].type === "Box") {
      return dataGroup[0].style;
    }
    return "regular";
  }

  return (firstArticle as Article).style || "regular";
};

export { mapTeaserStyle };
